import "../styles/index.scss";
import $ from 'jquery';
//Popup open//

const AppIcon = document.querySelector(".download-app-img");

AppIcon.addEventListener("click", ()=> {
    document.querySelector(".popup").classList.add("popup-active");
    document.querySelector(".body").classList.add("body-fixed");
});

//Popup close//

const closeIcon = document.querySelectorAll(".close-pupup");

closeIcon.forEach(close => {
    close.addEventListener("click", ()=> {
        document.querySelector(".popup").classList.remove("popup-active");
        document.querySelector(".thank-you").style.display = 'none';
        document.querySelector(".body").classList.remove("body-fixed");
    });
});



$(function() { 
    $(".user_subscribe").on("submit", function(e) {
        e.preventDefault();
        var data = $(this).serialize();
        var form = $(this);
        
        $.ajax({
            type: "POST",
            url: "/subscribe.php",
            data: data,
            success: function(response){
                response = JSON.parse(JSON.stringify(response));
                form.find(".response").css("color", "#E53E3E");
                form.find(".response").html(response.message);
                form.find(".response").show();
                if(response.status) {
                    $(".thank-you").css("display", "flex");
                    form.find(".response").hide();
                }
            }
        })
    })
})